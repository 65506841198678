
import { Component, Vue } from "vue-property-decorator";
import Header from "@/components/header.vue"; // @ is an alias to /src
import accountService from "../../services/account-service";
import projectService from "../../services/project-service";
import PopUp from "@/components/PopUp.vue";

import Loader from "@/components/loader.vue"; // @ is an alias to /src

const moment = require("moment");

import { VueTreeList, Tree } from "vue-tree-list";

@Component({
  components: { Header, VueTreeList, Loader,PopUp },
})
export default class CostCenterSupport extends Vue {
  public filter = null;
  public currentItem = null;
  public currentIndex = -1;
  public currentPage = 1;
  public title = "";
  public perpage = 100;
  search_code = "";
  public option_group: any = [];
  option_accounts: any = [];
  obj_params: any = {};
  selected_item_coa: any = {};
  params_parent_code: any = {};
  searchTerm = "";
  public import_file: any = null;
  loading = true;
  public import_response = "";

  newTree: any = {};
  data: any = null;
  public fields = [
    {
      key: "code",
    },
    {
      key: "title",
    },
    {
      key: "node_type",
      label: "Node Type",
    },
    {
      key: "created_at",
      label: "Date",
      formatter: (value: any) => {
        return moment(String(value)).format("MM/DD/YYYY");
      },
    },
  ];

  get rows() {
    return this.selected_items.length;
  }
  public selected_items = [
    {
      id: "",
      title: "",
      created_at: "",
    },
  ];
// For Modal
modalShow = false;
  modalMessage: string[] = [];
  modalSimpleMessage: string = "";
  modalType = "";
  closeModal() {
    this.modalShow = false;
    this.modalMessage = [];
    this.modalType = "";
    this.modalSimpleMessage = ""
  }
  showModal(message: string, array: string[], type: "success" | "error" | "errorArray",) {
    this.modalShow = true;
    this.modalMessage = array;
    this.modalType = type;
    this.modalSimpleMessage = message
  }
  // modal end
  // onRowClicked(item: any, index: any, event: any) {
  //   this.selected_item_coa = item;
  //   this.$root.$emit("bv::toggle::collapse", "sidebar-variant-show");
  // }

  retrieve() {
    this.loading = true;
    accountService
      .getAccountGroup()
      .then((response) => {
        let group = response.data;
        this.option_group = group.map((item: any) => {
          return { value: item.title, text: item.title };
        });
      })
      .catch((e) => {
        console.log(e);
        this.loading = false;
      });

    projectService
      .getProjectsHierarchy()
      .then((res) => {
        this.data = new Tree(res.data);
        this.loading = false;
      })
      .catch((err) => {
        console.log(err);
        this.loading = false;
      });
  }

  submit_import() {
    const isValidFile = this.validateFile(this.import_file);
    if(!isValidFile){
      this.showModal("Invalid file. Please upload a valid file by download the sample.",[],"error")
    }else {
      this.import_response = "Please wait, it will take time.";
    this.loading = true;
    this.import_response = "";
     
    if (this.import_file != null) {
      projectService
        .importBulk(this.import_file)
        .then((response) => {
          this.import_response = "Uploaded.";
          this.loading = false;
        })
        .catch((e) => {
          this.import_response = "Error occured while uploading.";
          this.loading = false;
          console.log(e);
        });
    } else {
      this.import_response = "Please upload the file";
      this.loading = false;
    }
    }   
  }

  onClick(params: any) {
    projectService
      .getChilds(params.id)
      .then((res) => {
        this.selected_items = res.data.result;
      })
      .catch((err) => {
        console.log(err);
      });
    // this.datatre = new Tree([
    //   {
    //     name: 'Balance Sheet',
    //     id: 1,
    //     pid: 0,
    //     dragDisabled: true,
    //     addTreeNodeDisabled: true,
    //     addLeafNodeDisabled: true,
    //     editNodeDisabled: true,
    //     delNodeDisabled: true,
    //     children: [
    //       {
    //         name: 'Assets',
    //         id: 3,
    //         isLeaf: false,
    //         pid: 1,
    //         dragDisabled: true,
    //         addTreeNodeDisabled: true,
    //         addLeafNodeDisabled: true,
    //         editNodeDisabled: true,
    //         delNodeDisabled: true,
    //       },
    //       {
    //         name: 'Liabilities',
    //         id: 4,
    //         pid: 1,
    //         dragDisabled: true,
    //         addTreeNodeDisabled: true,
    //         addLeafNodeDisabled: true,
    //         editNodeDisabled: true,
    //         delNodeDisabled: true,
    //       },
    //       {
    //         name: 'Equity',
    //         id: 5,
    //         pid: 1,
    //         dragDisabled: true,
    //         addTreeNodeDisabled: true,
    //         addLeafNodeDisabled: true,
    //         editNodeDisabled: true,
    //         delNodeDisabled: true,
    //       }

    //     ]
    //   },
    //   {
    //     name: '	Profit & Loss',
    //     id: 2,
    //     pid: 0,
    //     dragDisabled: true,
    //     addTreeNodeDisabled: true,
    //     addLeafNodeDisabled: true,
    //     editNodeDisabled: true,
    //     delNodeDisabled: true,
    //     children: [{
    //       name: 'Expenses',
    //       id: 6,
    //       pid: 2,
    //       dragDisabled: true,
    //       addTreeNodeDisabled: true,
    //       addLeafNodeDisabled: true,
    //       editNodeDisabled: true,
    //       delNodeDisabled: true,
    //     },
    //     {
    //       name: 'Revenue',
    //       id: 7,
    //       pid: 2,
    //       dragDisabled: true,
    //       addTreeNodeDisabled: true,
    //       addLeafNodeDisabled: true,
    //       editNodeDisabled: true,
    //       delNodeDisabled: true,
    //     }]
    //   },
    // ]);
  }

  create() {
    this.obj_params["parent_code"] = this.params_parent_code.id;
    this.obj_params["parent_title"] = this.params_parent_code.title;
    if (this.obj_params.id) {
      accountService
        .updateAccount(this.obj_params)
        .then((response) => {
          this.retrieve();
          this.obj_params = {};
          this.option_accounts = [];
          this.params_parent_code = {};
        })
        .catch((e) => {
          console.log(e);
        });
      // this.$root.$emit("bv::toggle::collapse", "sidebar-variant-show");
    } else {
      accountService
        .createAccount(this.obj_params)
        .then((response) => {
          this.retrieve();
          this.obj_params = {};
          this.option_accounts = [];
          this.params_parent_code = {};
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }

  search_parent_account(title: any) {
    if (title.length > 4) {
      accountService
        .getByTitle(title)
        .then((response) => {
          this.option_accounts = response.data.result;
        })
        .catch((e) => {
          console.log(e);
        });
    }
    if (title.length == 0) {
      this.option_accounts = [];
    }
  }

  get_parent_account(data: any) {
    this.params_parent_code = data;
    if (this.params_parent_code) {
      this.option_accounts = [];
    }
  }

  edit_account(data: any) {
    this.obj_params = data;
    this.params_parent_code.id = data["parent_code_id"];
    this.params_parent_code.title = data["parent_title"];
    this.$root.$emit("bv::toggle::collapse", "sidebar-variant-create");
  }

  search_code_method(code: any) {
    accountService
      .getByTitleMaster(code)
      .then((response) => {
        this.selected_items = response.data.result;
      })
      .catch((e) => {
        console.log(e);
      });
  }

  reset_search() {
    this.retrieve();
    this.search_code = "";
  }

  mounted() {
    this.retrieve();
  }

  reset() {
    this.obj_params = {};
    this.option_accounts = [];
    this.params_parent_code = {};
  }
  validateFile(file:any) {
      // Check if a file is selected
      if (!file) {
        return null;
      }
      // Get the file name
      const fileName = file.name;

      // Define allowed file extensions (PDF and Excel)
      const allowedExtensions = ['.xls', '.xlsx','.ods','.xlsm,','.xlsb','.xltx','.xltm','.xlam'];
      // Check if the file has an allowed extension
      const isValid = allowedExtensions.some(extension => fileName.toLowerCase().endsWith(extension));
      return isValid ? true : false;
    }
}
